import { createAction } from 'redux-actions'

import { createActionsGroup, createActionCreator } from 'utils/redux-utils'

export const templatesActions = createActionsGroup('TEMPLATES')
export const templateStatusChange = createAction('TEMPLATE_STATUS_CHANGE')
export const templateItemsActions = createActionsGroup('TEMPLATE_ITEMS')
export const templateNotAddedItemsActions = createActionsGroup(
  'TEMPLATE_NOT_ADDED_ITEMS',
)

export const ADD_TEMPLATE_TO_CART = 'ADD_TEMPLATE_TO_CART'
export const addTemplateToCartActions = createActionsGroup(ADD_TEMPLATE_TO_CART)

export const PRODUCT_TEMPLATES_ACTION = 'PRODUCT_TEMPLATES_ACTION'
export const productTemplatesAction = createActionCreator(
  PRODUCT_TEMPLATES_ACTION,
)

export const DELETE_PRODUCT_FROM_TEMPLATE = 'DELETE_PRODUCT_FROM_TEMPLATE'
export const deleteProductFromTemplate = createActionCreator(
  DELETE_PRODUCT_FROM_TEMPLATE,
)

export const ADD_PRODUCT_TO_TEMPLATE = 'ADD_PRODUCT_TO_TEMPLATE'
export const addProductToTemplate = createActionCreator(ADD_PRODUCT_TO_TEMPLATE)

export const getTemplateProductDetails = createActionsGroup(
  'GET_TEMPLATE_PRODUCT_DETAILS',
)

export const getTemplateActions = createActionsGroup('GET_TEMPLATE')
