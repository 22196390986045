import { createSelector } from 'reselect'
import { getOr, find } from 'lodash/fp'
import {
  makeDataSelector,
  makeIsFinishedSelector,
  makePaginationSelector,
  makeImmutableStateSelector,
} from 'utils/redux-utils'

import {
  TEMPLATES_REDUCER_NAME,
  TEMPLATES_INFO_REDUCER_NAME,
  CURRENT_TEMPLATE_REDUCER_NAME,
  TEMPLATE_PRODUCT_DETAILS_REDUCER_NAME,
  TEMPLATE_NOT_ADDED_ITEMS_REDUCER_NAME,
} from './consts'

export const templatesData = makeDataSelector([
  TEMPLATES_INFO_REDUCER_NAME,
  TEMPLATES_REDUCER_NAME,
])

export const selectTemplatesData = createSelector(
  templatesData,
  data => data || [],
)

export const templateByIdSelector = id =>
  createSelector(selectTemplatesData, find({ id }))

export const selectTemplatesFinished = makeIsFinishedSelector([
  TEMPLATES_INFO_REDUCER_NAME,
  TEMPLATES_REDUCER_NAME,
])

export const templateDetailsData = makeDataSelector([
  TEMPLATES_INFO_REDUCER_NAME,
  CURRENT_TEMPLATE_REDUCER_NAME,
])

export const selectTemplateDetailsData = createSelector(
  templateDetailsData,
  getOr([], 'items'),
)

export const selectTemplateDetailsPagination = makePaginationSelector(
  templateDetailsData,
)

export const selectTemplateDetailsFinished = makeIsFinishedSelector([
  TEMPLATES_INFO_REDUCER_NAME,
  CURRENT_TEMPLATE_REDUCER_NAME,
])

const selectTemplateProductDetails = makeDataSelector([
  TEMPLATES_INFO_REDUCER_NAME,
  TEMPLATE_PRODUCT_DETAILS_REDUCER_NAME,
])

export const templateProductDetailsSelector = createSelector(
  selectTemplateProductDetails,
  data => data || {},
)

export const templateProductDetailsIsFetched = makeIsFinishedSelector([
  TEMPLATES_INFO_REDUCER_NAME,
  TEMPLATE_PRODUCT_DETAILS_REDUCER_NAME,
])

const selectTemplateNotAdded = state =>
  makeImmutableStateSelector(state, [
    TEMPLATES_INFO_REDUCER_NAME,
    TEMPLATE_NOT_ADDED_ITEMS_REDUCER_NAME,
  ])

export const selectTemplateNotAddedData = createSelector(
  selectTemplateNotAdded,
  data => data?.toJS() || [],
)
