import { useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useLazyGetTemplateFiltersQuery } from 'containers/Templates/rtkApi'
import { makeSelectedFiltersParams } from 'components/Filters/utils'
import { STATIC_FILTERS } from 'components/Filters/consts'
import { templateItemsActions } from 'containers/Templates/actions'

const DEFAULT_FILTERING_SETTINGS = {
  filters: {},
  sortBy: '',
  search: '',
  page: 1,
}

export const useTemplateItemFiltering = ({ type }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [
    fetchFilters,
    { data: fetchedFilters = [] },
  ] = useLazyGetTemplateFiltersQuery()
  const availableFilters = [...fetchedFilters, ...STATIC_FILTERS]

  const [filteringSettings, setFilteringSettings] = useState(
    DEFAULT_FILTERING_SETTINGS,
  )

  useLayoutEffect(
    () => {
      if (id) {
        updateFilteringSettings(DEFAULT_FILTERING_SETTINGS)
      }
    },
    [id],
  )

  const fetchItems = ({ filters, sortBy, search, page } = {}) => {
    const filterParams = makeSelectedFiltersParams(availableFilters, filters)

    dispatch(
      templateItemsActions.delta(
        {
          id,
          current: page,
          search: search || undefined,
          sortBy: sortBy || undefined,
          ...filterParams,
        },
        { discardPrevItems: page < 2, type },
      ),
    )

    fetchFilters({
      id,
      selectedFiltersParams: filterParams,
    })
  }

  const updateFilteringSettings = values => {
    const newFilteringSettings = {
      ...filteringSettings,
      ...values,
      page: values.page || 1,
    }

    fetchItems(newFilteringSettings)
    setFilteringSettings(newFilteringSettings)
  }

  return {
    filteringSettings,
    setFilteringSettings,
    updateFilteringSettings,
    availableFilters,
    fetchItems,
  }
}
