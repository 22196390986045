import styled from 'styled-components'
import { Box } from '@rebass/grid'

import theme from 'theme'
import { Button, BUTTON_SIZE, BUTTON_TYPES } from 'components/Button'

export const SearchContainer = styled(Box)`
  margin-left: auto;
  position: absolute;
  right: 16px;
  background-color: ${props => props.isActive && theme.colors.lightGrey};
  border-radius: 8px;
  max-width: ${props => (!props.isActive ? '48px' : 'calc(100% - 32px)')};
  transition: max-width 0.4s;
  overflow: hidden;
  :hover {
    background-color: ${theme.colors.lightGrey};
  }
  width: 100%;
  @media screen and (min-width: ${p => p.$desktopBreakpoint}px) {
    width: auto;
  }
`

export const SearchBtn = styled(Button).attrs({
  size: BUTTON_SIZE.SEARCH,
  type: BUTTON_TYPES.BASIC,
})`
  position: absolute;
  width: 40px;
  padding: 0;
  top: 4px;
  left: 4px;
`

export const SearchInput = styled.input`
  font-size: 13px;
  color: ${theme.colors.primary};
  height: 40px;
  padding: 8px 40px 8px 48px;
  outline: none;

  // out transition
  background-color: ${p =>
    !p.disabled ? theme.colors.lightGrey : theme.colors.transparent};
  transition: background-color 0ms 0.3s;

  width: 100%;
  @media screen and (min-width: ${p => p.$desktopBreakpoint}px) {
    width: auto;
  }

  :disabled {
    pointer-events: none;
  }

  ::placeholder {
    font-size: ${theme.textSizes.xxs};
    line-height: 16px;
    color: ${theme.colors.gray7};
  }
`

export const ClearBtnContainer = styled(Box)`
  position: absolute;
  text-align: center;
  cursor: pointer;
  padding: 4px 0;
  width: 40px;
  right: 4px;
  top: 4px;
  :hover {
    path {
      stroke: ${theme.colors.primary};
    }
  }
`
