import { values } from 'lodash/fp'

import theme from 'theme'

export const ORDER_INFO_REDUCER_NAME = 'orderInfo'
export const ORDER_DETAILS_REDUCER_NAME = 'orderDetails'
export const TRADE_LIMIT_REDUCER_NAME = 'tradeLimit'

export const ORDERED_SUCCESSFULLY = 'orderedSuccessfully'

export const ORDER_ERRORS = {
  POSITIONS_ERROR: 150,
  INACTIVE_PRODUCTS: 10206050033,

  // FE custom errors
  NO_TIMESLOTS_CODE: 'noTimeSlots',
  HAS_OUT_OF_STOCKS: 'hasOutOfStocks',
}

export const ORDER_ERRORS_LIST = values(ORDER_ERRORS)

export const POSITION_ERRORS = {
  ALCOHOL_LICENSE: 215,
}

export const ORDERS_PER_PAGE_SIZE = 48

export const PAY_SCENARIO = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
}

export const PAY_SCENARIO_WEIGHT = {
  [PAY_SCENARIO.GREEN]: 0,
  [PAY_SCENARIO.YELLOW]: 1,
  [PAY_SCENARIO.RED]: 2,
}

export const ORDER_PAY_BUTTON_COLOR = {
  GREEN: 0,
  YELLOW: 1,
  RED: 2,
}

export const PAYMENT_COLORS = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
}

export const PAYMENT_COLOR_WEIGHT = {
  [PAYMENT_COLORS.GREEN]: 0,
  [PAYMENT_COLORS.YELLOW]: 1,
  [PAYMENT_COLORS.RED]: 2,
}

export const PAYMENT_COLORS_MAP = {
  [PAYMENT_COLORS.GREEN]: theme.colors.secondary,
  [PAYMENT_COLORS.YELLOW]: theme.colors.orange,
  [PAYMENT_COLORS.RED]: theme.colors.red,
}

export const RESTRICTION_DRIVEN_BY = {
  CART_LIMIT: 'CART_LIMIT',
  INVOICES_STATUS: 'INVOICES_STATUS',
}

export const BELOW_LIMIT = 'BELOW_LIMIT'
