import React, { useEffect, useRef } from 'react'
import _ from 'lodash'

import { GoTo } from 'components/Icons'
import theme from 'theme'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'

const GO_TO_TOP_BUTTON_STYLES = {
  VISIBLE: {
    zIndex: theme.zIndex.base,
    opacity: 1,
  },
  INVISIBLE: {
    zIndex: -1,
    opacity: 0,
  },
}

const GoToTopButton = () => {
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const goToTopRef = useRef(null)

  const setGoToTopButtonStyle = style => {
    if (goToTopRef.current) {
      goToTopRef.current.style.opacity = style.opacity
      goToTopRef.current.style.zIndex = style.zIndex
    }
  }

  useEffect(() => {
    const debouncedSetGoToTop = _.debounce(setGoToTopButtonStyle, 100)

    const onScroll = () => {
      const { scrollTop } = document.documentElement
      // directly changing styles for performance reasons
      if ((isDesktop && scrollTop > 160) || (!isDesktop && scrollTop > 324)) {
        debouncedSetGoToTop(GO_TO_TOP_BUTTON_STYLES.VISIBLE)
      } else {
        debouncedSetGoToTop(GO_TO_TOP_BUTTON_STYLES.INVISIBLE)
      }
    }

    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <button
      type="button"
      ref={goToTopRef}
      className="self-end flex justify-center items-center w-10 h-10 bg-white shadow-modal rounded-lg sticky right-0 opacity-0 bottom-4 ease-out duration-300"
      onClick={() =>
        window.scrollTo({ top: isDesktop ? 149 : 294, behavior: 'smooth' })
      }
    >
      <GoTo color={theme.colors.primary} />
    </button>
  )
}

export default GoToTopButton
