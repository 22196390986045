import { useState } from 'react'
import { useInView } from 'react-intersection-observer'

const useHasSeenElement = () => {
  const [hasSeenElement, setHasSeenElement] = useState(false)

  const [elementRef] = useInView({
    delay: 50,
    onChange: inView => inView && setHasSeenElement(true),
  })

  return [elementRef, hasSeenElement, setHasSeenElement]
}

export default useHasSeenElement
