import { makeMessages, makeTranslation } from 'utils'

const messages = {
  orderNumber: 'orderNumber',
  total: 'total',
  status: 'status',
  orderDatetime: 'orderDatetime',
  deliveryDate: 'deliveryDate',
  orderSource: 'orderSource',
  orders: { id: 'navbar.orders' },
}

export default makeMessages(messages, makeTranslation('orders.header'))
