import styled from 'styled-components'

import { getSpacing } from 'components/helpers/theme-selectors'

export const Container = styled.div`
  margin-top: ${props => getSpacing(props).base};
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${props => getSpacing(props).base} auto
    ${props => getSpacing(props).sm};
`
