import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { ORDER_STATUS } from 'views/Orders/consts'

import { cn } from 'utils'
import messages from 'views/Orders/messages'

const ORDER_STATUS_CONFIG = {
  [ORDER_STATUS.TO_BE_ACCEPTED]: {
    bgColor: theme.colors.orange3,
    textColor: theme.colors.orangeDark2,
  },
  [ORDER_STATUS.ACCEPTED]: {
    bgColor: theme.colors.greenLight5,
    textColor: theme.colors.secondary,
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    bgColor: theme.colors.greenLight5,
    textColor: theme.colors.secondary,
  },
  [ORDER_STATUS.COMPLETED]: {
    bgColor: theme.colors.semiTranspBg.grayLight,
    textColor: theme.colors.lightGrey4,
  },
  [ORDER_STATUS.CANCELED]: {
    bgColor: theme.colors.redLight2,
    textColor: theme.colors.redDarkText,
  },
}

const OrderStatusTag = ({ orderStatusId, className, textClassName }) => {
  const { formatMessage } = useIntl()

  const config = ORDER_STATUS_CONFIG[orderStatusId]

  if (!config) return null

  return (
    <div
      data-test-id="order-status-tag"
      className={cn('rounded-md px-1', className)}
      style={{ backgroundColor: config.bgColor }}
    >
      <span
        className={cn('text-[11px] leading-5 font-medium', textClassName)}
        style={{ color: config.textColor }}
      >
        {formatMessage(messages[`status${orderStatusId}`])}
      </span>
    </div>
  )
}

export default OrderStatusTag
