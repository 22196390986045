import React from 'react'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'

import TemplateFilters from './TemplateFilters'
import TemplateSorting from './TemplateSorting'
import Search from './Search'
import { SearchSortingContainer, ToolBarContainer } from './styles'

const TemplateItemsToolbar = ({
  updateFilteringSettings,
  filteringSettings,
  availableFilters,
  isTemplateAutomatic,
  scrollToTopOfList,
  type,
}) => {
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  return (
    <>
      <ToolBarContainer id="template-list-toolbar">
        <TemplateFilters
          updateFilteringSettings={updateFilteringSettings}
          filteringSettings={filteringSettings}
          availableFilters={availableFilters}
          isTemplateAutomatic={isTemplateAutomatic}
          scrollToTopOfList={scrollToTopOfList}
          type={type}
        />
        {isDesktop && (
          <SearchSortingContainer>
            <TemplateSorting
              isTemplateAutomatic={isTemplateAutomatic}
              updateFilteringSettings={updateFilteringSettings}
              filteringSettings={filteringSettings}
              type={type}
            />
            <Search
              updateFilteringSettings={updateFilteringSettings}
              filteringSettings={filteringSettings}
              type={type}
            />
          </SearchSortingContainer>
        )}
      </ToolBarContainer>

      {!isDesktop && (
        <SearchSortingContainer>
          <TemplateSorting
            isTemplateAutomatic={isTemplateAutomatic}
            updateFilteringSettings={updateFilteringSettings}
            filteringSettings={filteringSettings}
            type={type}
          />
          <Search
            updateFilteringSettings={updateFilteringSettings}
            filteringSettings={filteringSettings}
            type={type}
          />
        </SearchSortingContainer>
      )}
    </>
  )
}

export default TemplateItemsToolbar
