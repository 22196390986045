import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  useLazyGetProductRecommendationsQuery,
  useGetProductRecommendationsState,
  useGetProductReplacementsQuery,
} from 'containers/Products/rtkApi'
import useHasSeenElement from 'hooks/useHasSeenElement'

import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

import messages from '../messages'

const RECOMMENDED_TYPES = {
  FBT: 'FREQUENTLY_BOUGHT_TOGETHER',
  OTHERS: 'OTHERS_YOU_MAY_LIKE',
}

const usePDPCarouselData = ({ isUnitDisabled, productId }) => {
  const { formatMessage } = useIntl()
  const [carouselRef, hasSeenCarousel, setHasSeenCarousel] = useHasSeenElement()

  const [fetchProductRecommendations] = useLazyGetProductRecommendationsQuery()
  const {
    data: {
      products: recommendations = [],
      meta: { experiment = {}, attributionToken } = {},
    } = {},
    isFetching: isRecommendationsFetching,
    isUninitialized: isRecommendationsUninitialized,
  } = useGetProductRecommendationsState({ id: productId })

  const itemModelId = experiment?.id
  const itemModelType = experiment?.modelType

  const {
    data: { products: replacements = [] } = {},
    isFetching: isReplacementsFetching,
  } = useGetProductReplacementsQuery(
    { id: productId },
    { skip: !productId || !isUnitDisabled },
  )

  useEffect(
    () => {
      if (productId && hasSeenCarousel && !isUnitDisabled) {
        fetchProductRecommendations({ id: productId })
      }
    },
    [productId, hasSeenCarousel, isUnitDisabled],
  )

  useEffect(
    () => {
      setHasSeenCarousel(false)
    },
    [productId, setHasSeenCarousel],
  )

  const carouselTitle = useMemo(
    () => {
      if (isUnitDisabled) {
        return formatMessage(messages.replacements)
      }

      if (itemModelType === RECOMMENDED_TYPES.OTHERS) {
        return formatMessage(messages.othersYouMayLike)
      }

      return formatMessage(messages.frequentlyBoughtTogether)
    },
    [isUnitDisabled, itemModelType],
  )

  const {
    carouselProducts,
    isCarouselLoading,
    itemListName,
    itemListId,
  } = useMemo(() => ({
    carouselProducts: isUnitDisabled ? replacements : recommendations,
    isCarouselLoading: isUnitDisabled
      ? isReplacementsFetching
      : isRecommendationsFetching || isRecommendationsUninitialized,
    itemListName: isUnitDisabled
      ? ITEM_LIST_NAMES.SUBSTITUTES
      : ITEM_LIST_NAMES.RECOMMENDATION_PDP,
    itemListId: isUnitDisabled
      ? ITEM_LIST_IDS.SUBSTITUTES
      : ITEM_LIST_IDS.RECOMMENDATION_PDP,
  }))

  return {
    carouselRef,
    carouselTitle,
    carouselProducts,
    isCarouselLoading,
    itemListName,
    itemListId,
    itemModelId,
    attributionToken,
  }
}

export default usePDPCarouselData
