import { values } from 'lodash/fp'
import {
  Bio,
  Eco,
  GlutenFree,
  Halal,
  Kosher,
  LactoseFree,
  PreservativeFree,
  SugarFree,
  Vegan,
  Vegetarian,
} from 'components/Icons/Labels'
import {
  Breakfast,
  Burger,
  Croissant,
  Kebab,
  Pizza,
  Polish,
  Sushi,
} from 'components/Icons/Cuisines'

export const AVAILABLE_FILTERS_REDUCER_NAME = 'availableFilters'
export const SELECTED_FILTERS_REDUCER_NAME = 'selectedFilters'

export const FILTERS_TYPES = {
  CATEGORIES: 'categories',
  BRANDS: 'brands',
  ON_STOCK: 'onStock',
  PROMOTION: 'promotion',
  CLEARANCE: 'clearance',
  STORAGE_TYPES: 'storageTypes',
  PRODUCT_FEATURES: 'productFeatures',
  OFFER_FEATURES: 'offerFeatures',
  CUISINE_FEATURES: 'cuisineFeatures',
}

export const FILTERS_VALUES = {
  ON_STOCK: {
    ALL: 'all',
    AVAILABLE: 1,
  },
  PROMOTION: {
    ALL: 'all',
    AVAILABLE: 1,
  },
  CLEARANCE: {
    ALL: 'all',
    AVAILABLE: 1,
  },
  STORAGE_TYPES: {
    ROOM_TEMP: 'S',
    FROZEN: 'M',
    CHILLED: 'Z',
  },
  OFFER_FEATURES: {
    BESTSELLER: 'BESTSELLER',
    NOVELTY: 'NOVELTY',
    BIDMARKET: 'BIDMARKET',
    RECOMMENDATION: 'RECOMMENDATION',
  },
}

export const FILTER_TYPES_LIST = values(FILTERS_TYPES)

export const FILTER_ITEMS = {
  ALL: 'all',
}

export const SELECT_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  BUTTON: 'button',
  GROUP_BUTTON: 'group button',
}

export const MOBILE_FILTERS_QUERY = '(max-width: 640px)'

export const FILTER_SECTION = {
  ALL: 'all',
  CATEGORY: 'category',
  GROUP: 'group',
  SUBGROUP: 'subgroup',
}

export const FILTER_CHILD_HIERARCHY = {
  [FILTER_SECTION.ALL]: [
    FILTER_SECTION.CATEGORY,
    FILTER_SECTION.GROUP,
    FILTER_SECTION.SUBGROUP,
  ],
  [FILTER_SECTION.CATEGORY]: [FILTER_SECTION.GROUP, FILTER_SECTION.SUBGROUP],
  [FILTER_SECTION.GROUP]: [FILTER_SECTION.SUBGROUP],
  [FILTER_SECTION.SUBGROUP]: [],
}

export const STATIC_FILTERS = [
  {
    filterField: 'filter.promotion',
    translateLabels: true,
    type: 'single',
    items: [
      {
        value: null,
        label: 'filter.promotion.all',
      },
      {
        value: 1,
        label: 'filter.promotion.available',
      },
    ],
  },
  {
    filterField: 'filter.clearance',
    translateLabels: true,
    type: 'single',
    items: [
      {
        value: null,
        label: 'filter.clearance.all',
      },
      {
        value: 1,
        label: 'filter.clearance.available',
      },
    ],
  },
]

export const FILTERS_TEST_IDS = {
  CATEGORIES: 'categories_filter',
  ON_STOCK: 'on_stock_filter',
  PROMOTION: 'promotion_filter',
  CLEARANCE: 'clearance_filter',
  ROOM_TEMP: 'room_temp_filter',
  CHILLED: 'chilled_filter',
  FROZEN: 'frozen_filter',
  BRANDS: 'brands_filter',
  PRODUCT_FEATURES: 'product_features_filter',
  OFFER_FEATURES: 'offer_features_filter',
  CUISINE_FEATURES: 'cuisine_features_filter',
}

export const PRODUCT_FEATURE_ICON = {
  BIO: Bio,
  ECO: Eco,
  GLUTEN_FREE: GlutenFree,
  HALAL: Halal,
  KOSHER: Kosher,
  LACTOSE_FREE: LactoseFree,
  PRESERVATIVES_FREE: PreservativeFree,
  SUGAR_FREE: SugarFree,
  VEGAN: Vegan,
  VEGETARIAN: Vegetarian,
}

export const CUISINE_ICON = {
  ITALIAN: Pizza,
  ASIAN: Sushi,
  BREAKFAST: Breakfast,
  HOTEL: Croissant,
  KEBAB: Kebab,
  AMERICAN: Burger,
  POLISH: Polish,
}
