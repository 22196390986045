import React, { useEffect, useRef, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { cn } from 'utils'
import SearchInput from 'components/Input/Search'
import { ORDERS_LIST_DESKTOP_START } from 'views/Orders/consts'

import messages from 'views/Orders/messages'

const Search = ({
  value,
  setValue,
  isActive,
  setIsActive,
  validateSearch,
  debouncedSearch,
  onClearBtnClick,
  hasSearch,
}) => {
  const { formatMessage } = useIntl()
  const searchRef = useRef(null)

  const inputRef = searchRef.current?.inputRef
  const containerRef = searchRef.current?.containerRef

  useEffect(
    () => {
      if (isActive) {
        inputRef?.focus()
      } else {
        inputRef?.blur()
      }
    },
    [isActive],
  )

  const handleSearchBtnClick = useCallback(
    () => {
      if (value) return
      setIsActive(state => !state)
    },
    [value, setIsActive],
  )

  const handleInputBlur = useCallback(
    e => {
      // don't blur if clicked element is inside container
      const clickedEl = e.relatedTarget
      if (containerRef?.contains(clickedEl)) {
        return
      }

      if (!value) setIsActive(false)
    },
    [containerRef, value, setIsActive],
  )

  const handleInputChange = useCallback(
    val => {
      setValue(val)
      debouncedSearch(val)
    },
    [setValue, debouncedSearch],
  )

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        debouncedSearch.cancel()
        validateSearch(e.target.value)
      }
    },
    [debouncedSearch, validateSearch],
  )

  return (
    <SearchInput
      ref={searchRef}
      isActive={isActive}
      containerTestId="orders-search-container"
      onSearchBtnClick={handleSearchBtnClick}
      searchBtnTestId="orders-search-button"
      inputTestId={`orders-search-input-${isActive ? 'active' : 'inactive'}`}
      value={value}
      onKeyDown={handleKeyDown}
      onInputChange={handleInputChange}
      onInputBlur={handleInputBlur}
      clearBtnTestId="orders-search-clear"
      onClearBtnClick={onClearBtnClick}
      desktopBreakpoint={ORDERS_LIST_DESKTOP_START}
      className={cn(
        'lg-orders-list:!w-full lg-orders-list:!right-4 lg-orders-list:pointer-events-none hover:lg-orders-list:!bg-inherit',
        isActive &&
          'lg-orders-list:!max-w-[25%] lg-orders-list:pointer-events-auto',
        !hasSearch && 'hidden',
      )}
      btnClassName={cn(
        '[&_path]:group-hover:lg-orders-list:stroke-blue-900 [&_circle]:group-hover:lg-orders-list:stroke-blue-900',
        !isActive &&
          '[&_path]:lg-orders-list:stroke-grey-500 [&_circle]:lg-orders-list:stroke-grey-500',
      )}
      inputClassName="lg-orders-list:!w-full"
      clearClassName="lg-orders-list:!-right-2"
      placeholder={formatMessage(messages.searchPlaceholder)}
    />
  )
}

export default Search
