import React from 'react'

import theme from 'theme'

const NoOrders = ({ color = theme.colors.primary }) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 10C11.5 8.34315 12.8431 7 14.5 7H28.5H34.5C36.1569 7 37.5 8.34315 37.5 10V16V39.1315C37.5 39.9302 36.6099 40.4066 35.9453 39.9635L34.1774 38.7849C33.4718 38.3146 32.5463 38.3402 31.868 38.849L30.132 40.151C29.4537 40.6598 28.5282 40.6854 27.8226 40.2151L25.6094 38.7396C24.9376 38.2917 24.0624 38.2917 23.3906 38.7396L21.1774 40.2151C20.4718 40.6854 19.5463 40.6598 18.868 40.151L17.132 38.849C16.4537 38.3402 15.5282 38.3146 14.8226 38.7849L13.0547 39.9635C12.3901 40.4066 11.5 39.9302 11.5 39.1315V10Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 13L32.5 13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16.5 19H26.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16.5 25H22.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default NoOrders
