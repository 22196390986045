import React from 'react'
import { useIntl } from 'react-intl'

import {
  makeItemsByDateGrouper,
  sortGroupedItems,
  isCurrentMonth,
  formatGroupTitle,
} from 'components/ItemsTable'
import Pagination from 'components/Pagination'
import { DEFAULT_PAGE_SIZE } from 'consts'

import messages from '../../../messages'
import OrderListItem from '../OrderListItem'

const groupOrders = makeItemsByDateGrouper(order => order.orderDatetime)

const OrderList = ({
  orders,
  ordersPagination,
  onPageChange,
  onShowOrderDetails,
}) => {
  const { formatMessage } = useIntl()
  const groupedOrders = groupOrders(orders)
  const sortedGroups = sortGroupedItems(groupedOrders)

  const currentPage = ordersPagination.currentPage || 1
  const totalCount = ordersPagination.totalCount || 0
  const totalPages = ordersPagination.totalPages || 1

  return (
    <div className="flex flex-col justify-between w-full">
      {sortedGroups.map((group, index) => (
        <section key={group} data-test-id={`orders-list-section#${index}`}>
          <div className="z-[1] pt-4 pb-2 bg-white border-b border-solid border-grey-300 sticky top-nav-height lg:top-[theme(inset.lg-nav-height)] lg-orders-list:top-[calc(theme(inset.lg-nav-height)+55px)] lg-orders-list:px-4 lg-orders-list:mb-[6px]">
            <span className="text-[13px] leading-6 font-medium text-grey-800 lg-orders-list:text-blue-900">
              {isCurrentMonth(group)
                ? formatMessage(messages.currentMonth)
                : formatGroupTitle(group)}
            </span>
          </div>

          {groupedOrders[group].map(order => (
            <OrderListItem
              key={order.orderId}
              order={order}
              onShowDetails={onShowOrderDetails}
            />
          ))}
        </section>
      ))}
      {totalCount > DEFAULT_PAGE_SIZE && (
        <div className="flex justify-end py-4 border-y border-solid border-grey-300 lg-orders-list:mt-6">
          <Pagination
            onChange={onPageChange}
            totalPages={totalPages}
            current={currentPage}
          />
        </div>
      )}
    </div>
  )
}

export default OrderList
