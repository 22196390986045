import { call, select, takeLatest, put } from 'redux-saga/effects'

import { getResponseError } from 'utils'
import {
  trackSearchedTemplate,
  trackSortingSelect,
  trackViewItemList,
  ITEM_LIST_NAMES,
} from 'services/analytics'
import { crudSwitch, genericGetDataEnhanced } from 'containers/App/sagas'
import { notifyAction } from 'containers/App/actions'
import { ALERT_TYPE } from 'components/Alert'
import { rtkApi } from 'services/api/rtkApi'
import { RTK_TEMPLATE_FILTERS_TAG } from 'containers/Templates/rtkApi'

import { selectTemplateDetailsData } from '../selectors'
import { templateItemsActions } from '../actions'
import {
  changeTemplateProductQuantity,
  deleteTemplateProduct,
  getTemplateItems,
} from '../api'
import { TEMPLATE_ITEMS_ERRORS } from '../consts'
import { getDefaultSorting } from './utils'
import { getTemplatesFlow } from './templates'

export function* getTemplateItemsFlow({
  data = {},
  additionalData: { discardPrevItems = false, type } = {},
}) {
  try {
    const defaultSortBy = getDefaultSorting(type)
    const itemsData = yield select(selectTemplateDetailsData)
    const response = yield call(genericGetDataEnhanced, {
      actions: templateItemsActions,
      request: getTemplateItems,
      params: data,
      dataSelector: ({ items, meta }) => ({
        meta: {
          ...meta,
          filters: { search: data.search },
          sortBy: data.sortBy || defaultSortBy,
        },
        items: discardPrevItems ? items : [...itemsData, ...items],
      }),
    })

    if (!response) return

    const { items } = response

    if (data.search) {
      const { id, search } = data

      trackSearchedTemplate({
        templateId: id,
        templateType: type,
        searchTerm: search,
        totalCount: items.length,
      })
    }

    if (data.sortBy) {
      const { id, sortBy } = data

      trackSortingSelect({
        templateId: id,
        templateType: type,
        sortingOption: sortBy,
      })
    }

    const products = items.map(item => ({
      ...item.product,
      unitOfMeasure: item.unitOfMeasure,
    }))

    trackViewItemList({
      products,
      itemListId: data.id,
      itemListName: ITEM_LIST_NAMES.TEMPLATE,
    })
  } catch (error) {
    yield put(
      notifyAction({
        message: getResponseError(error),
        type: ALERT_TYPE.ERROR,
      }),
    )
    if (
      error.body?.errors[0]?.code === TEMPLATE_ITEMS_ERRORS.TEMPLATE_NOT_EXISTS
    ) {
      yield call(getTemplatesFlow)
    }
  }
}

export function* changeTemplateItemQuantityFlow({
  data: { templateId, productId, successCallback, ...body } = {},
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: templateItemsActions,
      request: changeTemplateProductQuantity,
      params: {
        templateId,
        productId,
        body,
        idKey: 'productId',
      },
      method: 'update',
    })

    if (successCallback) yield call(successCallback)
  } catch (error) {
    yield put(
      notifyAction({
        message: getResponseError(error),
        type: ALERT_TYPE.ERROR,
      }),
    )
  }
}

export function* deleteProductFromTemplateItemsFlow({
  data: { productId, templateId, successCallback } = {},
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: templateItemsActions,
      request: deleteTemplateProduct,
      params: { productId, id: templateId },
      method: 'cancel', // do not change the store
    })
    yield call(getTemplatesFlow)

    yield put(rtkApi.util.invalidateTags([RTK_TEMPLATE_FILTERS_TAG]))

    if (successCallback) yield call(successCallback)
  } catch (error) {
    yield put(
      notifyAction({
        message: getResponseError(error),
        type: ALERT_TYPE.ERROR,
      }),
    )
  }
}

export default [
  takeLatest(templateItemsActions.DELTA, crudSwitch, {
    getSaga: getTemplateItemsFlow,
    updateSaga: changeTemplateItemQuantityFlow,
    deleteSaga: deleteProductFromTemplateItemsFlow,
  }),
]
