import React from 'react'
import 'whatwg-fetch'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'
import { defineMessages, useIntl, FormattedMessage } from 'react-intl'
import { translationMessages, DEFAULT_LOCALE } from 'i18n'
import _ from 'lodash'
import {
  compose,
  get,
  getOr,
  filter,
  equals,
  first,
  isNil,
  flow,
  find,
  negate,
  join,
  complement,
} from 'lodash/fp'
import theme from 'theme'
import qs from 'qs'

/* eslint-disable */
export const deepDiffObj = (base, onlyMissing) => object => {
  if (!object)
    throw new Error(`The object compared should be an object: ${object}`)
  if (!base) return object
  const result = _.transform(object, (result, value, key) => {
    if (!_.has(base, key)) result[key] = value // fix edge case: not defined to explicitly defined as undefined
    const areObjects = _.isPlainObject(value) && _.isPlainObject(base[key])
    if (
      !_.isEqual(value, base[key]) &&
      (!onlyMissing ||
        (areObjects && !_.isEmpty(deepDiffObj(base[key], onlyMissing)(value))))
    ) {
      result[key] = areObjects
        ? deepDiffObj(base[key], onlyMissing)(value)
        : value
    }
  })
  // map removed fields to undefined
  _.forOwn(base, (value, key) => {
    if (!_.has(object, key)) result[key] = undefined
  })
  return result
}
/* eslint-enable */

// cb args: (val, key)
export const mapKeysDeep = cb => obj => {
  if (!_.isPlainObject(obj)) return obj

  return _.mapValues(_.mapKeys(obj, cb), val => {
    if (_.isPlainObject(val)) return mapKeysDeep(cb)(val)
    if (_.isArray(val)) return _.map(val, v => mapKeysDeep(cb)(v))
    return val
  })
}

export const mapValuesDeep = iteree => obj =>
  _.isObject(obj)
    ? _.mapValues(obj, v => mapValuesDeep(iteree)(v))
    : iteree(obj)

export const cameliseDeep = value => {
  const cameliseFn = (val, key) => _.camelCase(key)
  return _.isArray(value)
    ? value.map(mapKeysDeep(cameliseFn))
    : mapKeysDeep(cameliseFn)(value)
}

export const snakeCaseDeep = value => {
  const snakeCaseFn = (val, key) => _.snakeCase(key)
  return _.isArray(value)
    ? value.map(mapKeysDeep(snakeCaseFn))
    : mapKeysDeep(snakeCaseFn)(value)
}

export const filterNullObjectValues = obj =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => !!value))

export const getResponseError = (response, locale = DEFAULT_LOCALE) => {
  const bodyErrors = _.get(response, 'body.errors')

  if (bodyErrors) {
    return bodyErrors.reduce((errors, { message, code }) => {
      const errorMessage = _.get(
        translationMessages,
        [locale, `Error.${code}`],
        message || code,
      )

      return [...errors, errorMessage]
    }, [])
  }

  return response.statusText || 'Connection error'
}

export const makeTranslation = scope => key =>
  _.isPlainObject(key)
    ? { id: key.id, defaultMessage: `missing ${key.id}` }
    : { id: `${scope}.${key}`, defaultMessage: `missing ${scope}.${key}` }

export const makeMessages = (messages, getTranslation) =>
  defineMessages(_.mapValues(messages, getTranslation))

// TODO use user chosen language when language switch will be implemented
export const formatPrice = (
  price,
  {
    withoutCurrency,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  } = {},
) => {
  const formattedPrice = `${_.round(Number(price / 100), 2).toLocaleString(
    'pl',
    {
      minimumFractionDigits,
      maximumFractionDigits,
    },
  )}${withoutCurrency ? '' : '\u00A0zł'}`

  // separating thousands by space ' '
  return formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0')
}

export const formatRoundPrice = price =>
  formatPrice(price, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

export const formatPricePerUnit = (price, unit) => {
  if (!!price && !!unit) return `${formatPrice(price)}\u00A0/\u00A0${unit}`

  return ''
}

export const useRichFormatMessage = () => {
  const { formatMessage } = useIntl()
  return (message, values) => {
    if (!message) {
      return ''
    }

    return formatMessage(message, {
      ...values,
      b: text => <b style={{ fontWeight: theme.fontWeights.bold }}>{text}</b>,
    })
  }
}

export const FormattedRichMessage = ({ values, ...rest }) => (
  <FormattedMessage
    values={{
      b: text => <b>{text}</b>,
      ...values,
    }}
    {...rest}
  />
)

export const isOutOfStock = ({ stock, nonStock }) => stock === 0 && !nonStock

export const isProductOutOfStock = product =>
  !product.unitsOfMeasure?.find(
    unit => unit.unitOfMeasure === product.baseUnitOfMeasure,
  ).stock && !product.nonStock

export const isElasticStock = ({ stock, nonStock }) => stock > 0 && nonStock

export const isElasticStockExceeding = ({ product, unitOfMeasureObj }) =>
  isElasticStock({
    stock: unitOfMeasureObj.stock,
    nonStock: product.nonStock,
  }) && unitOfMeasureObj.inCartQuantity > unitOfMeasureObj.stock

export const isProductInactive = ({
  units,
  product: { active, nonStock, unitsOfMeasure } = {},
}) =>
  !active ||
  isOutOfStock({
    nonStock,
    stock: flow(
      find({ unitOfMeasure: get('0.unitOfMeasure')(units) }),
      get('stock'),
    )(unitsOfMeasure),
  })

export const getProductTemplateInclusions = ({
  inSystemTemplate = false,
  templateIds = [],
}) => [
  inSystemTemplate ? templateIds.length > 1 : templateIds.length > 0,
  inSystemTemplate,
]

export const trimTitle = (title, limit = 80) =>
  title && title.length > limit ? `${title.slice(0, 80)}...` : title

export const parseProductTitle = (title, limit) => {
  let parsedTitle = title.split(' ')
  if (parsedTitle[parsedTitle.length - 1].length <= 4) {
    const lastEl = parsedTitle.pop()
    parsedTitle = parsedTitle.join(' ')
    parsedTitle = `${parsedTitle}\xa0${lastEl}`
  } else {
    parsedTitle = title
  }

  return trimTitle(parsedTitle, limit)
}

export const getPhoneNumber = ({ codeObj, number }) =>
  codeObj && number && `+${codeObj.dialCode}${number}`

export const extractFirstError = compose(
  first,
  getOr([], 'body.errors'),
)

export const extractErrorByCode = code =>
  compose(
    first,
    filter(
      compose(
        equals(code),
        get('code'),
      ),
    ),
    getOr([], 'body.errors'),
  )

export const isApiErrorOfCode = code =>
  compose(
    negate(isNil),
    extractErrorByCode(code),
  )

export const handleNumberChange = val =>
  String(val)
    // allowing only numbers, commas and dots
    .replace(/[^0-9.,]/g, '')
    // replacing commas with dots for API compliance
    .replace(/,/g, '.')
    // not allowing more than one dot
    .replace(/(\..*)\./g, '$1')

// prettier-ignore
export const isElementInViewport = (elm, threshold = 0, mode = 'visible') => {
  if (!elm) return false

  const rect = elm.getBoundingClientRect()
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight,
  )
  const above = rect.bottom - threshold < 0
  const below = rect.top - viewHeight + threshold >= 0

  // eslint-disable-next-line no-nested-ternary
  return mode === 'above'
    ? above
    : (mode === 'below' ? below : !above && !below)
}

export const hasMajorVersionBumped = (prevVersion, newVersion) => {
  const [prevVersionMajor] = prevVersion.split('.')
  const [newVersionMajor] = newVersion.split('.')

  return parseInt(newVersionMajor, 10) > parseInt(prevVersionMajor, 10)
}

export const getScrollbarWidth = () => {
  const bodyWidth = getOr(0, 'body.clientWidth')(document)

  return bodyWidth >= 1024 ? `${(window.innerWidth - bodyWidth) / 2}px` : '0px'
}

export const formatDeliveryAddress = (
  deliveryAddress,
  deliveryPostcode,
  deliveryCity,
  delimiter = <br />,
) => {
  const addressLine = compose(
    join(' '),
    filter(complement(isNil)),
  )([deliveryPostcode, deliveryCity])

  return deliveryAddress ? (
    <>
      {deliveryAddress}
      {delimiter}
      {addressLine}
    </>
  ) : (
    addressLine
  )
}

export const downloadBase64Pdf = ({ fileName, href }) => {
  fetch(href)
    .then(res => res.blob())
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.target = '_blank'
      downloadLink.download = `${fileName}.pdf`
      downloadLink.click()
    })
}

export const stringifyQuery = queryParams =>
  `?${qs.stringify(queryParams, {
    encode: false,
    arrayFormat: 'brackets',
  })}`

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const updateSearchParams = (params, newParams) =>
  new URLSearchParams({
    ...Object.fromEntries(params.entries()),
    ...newParams,
  })
