export const ORDERS_ROUTES = {
  LIST: '/orders',
  DETAILS: '/orders/:orderId',
}

export const ORDER_STATUS = {
  TO_BE_ACCEPTED: 0,
  ACCEPTED: 1,
  IN_PROGRESS: 2,
  CANCELED: 3,
  COMPLETED: 4,
}

export const CANCELLABLE_ORDER_STATUS = [
  ORDER_STATUS.TO_BE_ACCEPTED,
  ORDER_STATUS.ACCEPTED,
]

export const FINALIZED_ORDER_STATUS = [
  ORDER_STATUS.CANCELED,
  ORDER_STATUS.COMPLETED,
]

export const FLOATING_PRICE_ORDER_STATUS = [
  ORDER_STATUS.TO_BE_ACCEPTED,
  ORDER_STATUS.ACCEPTED,
  ORDER_STATUS.IN_PROGRESS,
]

export const ORDER_SOURCE_LABELS = {
  Online: 'orderDetails.source.online',
}

export const ORDER_ITEM_REMOVING_REASONS = {
  INCORRECT_ORDER_STATUS: 'INCORRECT_ORDER_STATUS',
  PAST_ORDER_DEADLINE: 'PAST_ORDER_DEADLINE',
  LOGISTIC_LIMIT_NOT_MET: 'LOGISTIC_LIMIT_NOT_MET',
  LAST_ORDER_ITEM: 'LAST_ORDER_ITEM',
  PROMOTIONAL_ITEM: 'PROMOTIONAL_ITEM',
  LOGISTIC_LIMIT_VIOLATION: 'LOGISTIC_LIMIT_VIOLATION',
  ALREADY_REMOVED: 'ALREADY_REMOVED',
}

export const ORDERS_LIST_DESKTOP_START = 1264
