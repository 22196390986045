import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose, get, prop, identity } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'

import { withModal } from 'hocs'
import { MODAL_OUT_OF_STOCK } from 'containers/App/modalTypes'
import {
  withPageView,
  trackOpenedTemplate,
  trackOutOfOfferProducts,
  SCREENS,
} from 'services/analytics'
import TemplateProductRow from 'components/Product/TemplateProductRow'
import {
  selectTemplateDetailsData,
  selectTemplateDetailsPagination,
  selectTemplateDetailsFinished,
} from 'containers/Templates/selectors'
import { templateItemsActions } from 'containers/Templates/actions'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'

import { isProductUnavailable } from 'components/Product/utils'
import Loader from 'components/Loader'

import TemplateItemsToolbar from './TemplateItemsToolbar'
import { isAutomatic } from '../utils'
import { useTemplateItemFiltering } from './utils'
import messages from '../messages'
import { GO_TO_TOP_OFFSET, TEMPLATE_TYPES } from '../consts'
import {
  TemplateDetailsWrapper,
  TemplateItemsContainer,
  TemplateNotAvailableContainer,
  TemplateItem,
  NoLongerActiveContainer,
  NoLongerActiveRemainingContainer,
  NoLongerActive,
  NoLongerActiveRemaining,
  NoLongerActiveCount,
  NoLongerActiveRemainingCount,
  TemplateProductListWrapper,
} from './styles'
import Header from './Header'
import GoToTopButton from './GoToTopButton'
import EmptyTemplate from './EmptyTemplate'
import OutOfStockModal from './OutOfStockModal'

const TemplateDetails = ({
  templateDetails = {},
  trackPageView = identity,
}) => {
  const dispatch = useDispatch()

  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const { name, type, id, itemsCount } = templateDetails
  const {
    updateFilteringSettings,
    filteringSettings,
    availableFilters,
  } = useTemplateItemFiltering(templateDetails)

  const isTemplateAutomatic = isAutomatic(type)

  const scrollToTopOfList = () => {
    const offset = isDesktop
      ? GO_TO_TOP_OFFSET.DESKTOP
      : GO_TO_TOP_OFFSET.MOBILE
    if ((window.scrollY || window.pageYOffset) > offset) {
      window.scrollTo({ top: offset, behavior: 'smooth' })
    }
  }

  const isItemsFinished = useSelector(selectTemplateDetailsFinished)
  const items = useSelector(selectTemplateDetailsData)

  const {
    totalPages,
    totalCount,
    inactiveProductsCount: inactiveNumber,
    currentPage,
  } = useSelector(selectTemplateDetailsPagination)

  const headProduct = get([0, 'product', 'active'], items)
  const hasInactiveProduct = items.length
    ? prop('active', headProduct) === false || !headProduct
    : false
  const isAllUnavailable = items.every(item => isProductUnavailable(item))

  useEffect(
    () => {
      const onScroll = () => {
        const {
          scrollHeight,
          scrollTop,
          clientHeight,
        } = document.documentElement

        const distanceFromBottom = scrollHeight - scrollTop - clientHeight
        if (
          distanceFromBottom <= 1000 &&
          currentPage < totalPages &&
          isItemsFinished
        ) {
          updateFilteringSettings({
            page: currentPage + 1,
          })
        }
      }

      window.addEventListener('scroll', onScroll, { passive: true })
      return () => window.removeEventListener('scroll', onScroll)
    },
    [currentPage, totalPages, isItemsFinished],
  )

  useEffect(
    () => {
      trackPageView()
      dispatch(templateItemsActions.clear())
    },
    [id],
  )

  useEffect(
    () => {
      if (type) {
        trackOpenedTemplate(type, id)
      }
    },
    [type, id],
  )

  useEffect(
    () => {
      if (hasInactiveProduct && currentPage === 1) {
        const products = items
          .filter(({ product: { active } }) => !active)
          .map(({ product }) => product.id)
        if (products.length) {
          trackOutOfOfferProducts(products, id)
        }
      }
    },
    [hasInactiveProduct, currentPage],
  )

  return (
    <TemplateDetailsWrapper>
      <Header
        editable={type === TEMPLATE_TYPES.CUSTOMER}
        {...{ name, id, totalCount, type, isAllUnavailable }}
      />
      {!!itemsCount && (
        <TemplateItemsToolbar
          filteringSettings={filteringSettings}
          updateFilteringSettings={updateFilteringSettings}
          isTemplateAutomatic={isTemplateAutomatic}
          type={type}
          scrollToTopOfList={scrollToTopOfList}
          availableFilters={availableFilters}
        />
      )}
      {isItemsFinished && !totalCount ? (
        <EmptyTemplate
          updateFilteringSettings={updateFilteringSettings}
          filteringSettings={filteringSettings}
          scrollToTopOfList={scrollToTopOfList}
        />
      ) : (
        <TemplateProductListWrapper>
          {hasInactiveProduct && (
            <TemplateNotAvailableContainer>
              <NoLongerActiveContainer>
                <NoLongerActive>
                  <FormattedMessage {...messages.noLongerActive} />
                </NoLongerActive>
                <NoLongerActiveCount>{inactiveNumber}</NoLongerActiveCount>
              </NoLongerActiveContainer>

              {items.map((product, index) => {
                if (product.product.active) {
                  return null
                }
                return (
                  <TemplateItem
                    key={product.id}
                    data-test-id="template-item-inactive"
                  >
                    <TemplateProductRow
                      templateType={type}
                      updateFilteringSettings={updateFilteringSettings}
                      templateId={id}
                      index={index}
                      isTemplateAutomatic={isTemplateAutomatic}
                      {...product}
                    />
                  </TemplateItem>
                )
              })}
            </TemplateNotAvailableContainer>
          )}
          <TemplateItemsContainer>
            {inactiveNumber !== 24 && (
              <NoLongerActiveRemainingContainer>
                <NoLongerActiveRemaining>
                  <FormattedMessage {...messages.noLongerActiveRemaining} />
                </NoLongerActiveRemaining>
                {isItemsFinished && (
                  <NoLongerActiveRemainingCount>
                    {totalCount - inactiveNumber}
                  </NoLongerActiveRemainingCount>
                )}
              </NoLongerActiveRemainingContainer>
            )}
            {items.map((product, index) => {
              if (!product.product.active) {
                return null
              }
              return (
                <TemplateItem
                  key={product.id}
                  data-test-id="template-item-active"
                >
                  <TemplateProductRow
                    templateType={type}
                    updateFilteringSettings={updateFilteringSettings}
                    templateId={id}
                    index={index}
                    isTemplateAutomatic={isTemplateAutomatic}
                    {...product}
                  />
                </TemplateItem>
              )
            })}
          </TemplateItemsContainer>
          {!isItemsFinished && <Loader />}
        </TemplateProductListWrapper>
      )}
      <GoToTopButton />
    </TemplateDetailsWrapper>
  )
}

export default compose(
  withPageView(SCREENS.TEMPLATE_DETAILS),
  withModal(OutOfStockModal, MODAL_OUT_OF_STOCK),
)(TemplateDetails)
