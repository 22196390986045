import React from 'react'
import { useParams } from 'react-router-dom'

import {
  trackCategoriesFilterClick,
  trackBrandsFilterClick,
  trackProductFeaturesClick,
  trackCuisineFeaturesClick,
  trackOfferFeaturesOpen,
  trackFilters,
} from 'services/analytics'
import Filters from 'components/Filters'

import { TemplateFiltersContainer } from './styles'

const TemplateFilters = ({
  filteringSettings,
  updateFilteringSettings,
  availableFilters,
  isTemplateAutomatic,
  scrollToTopOfList,
}) => {
  const { id } = useParams()

  const resetFilters = () => {
    updateFilteringSettings({
      filters: {},
    })
  }

  const handleFiltersApply = (
    name,
    filters,
    isMultipleFilters,
    eventValues,
  ) => {
    scrollToTopOfList()

    trackFilters({
      templateId: id,
      filterName: name,
      filterValues: filters,
      eventValues: {
        ...eventValues,
        templateType: isTemplateAutomatic ? 'automatic' : 'manual',
      },
    })

    const newSelectedFilters = isMultipleFilters
      ? {
          ...filteringSettings.filters,
          ...filters,
        }
      : {
          ...filteringSettings.filters,
          [name]: { ...filters },
        }

    updateFilteringSettings({
      filters: newSelectedFilters,
      search: '',
    })
  }

  return (
    <TemplateFiltersContainer>
      <Filters
        isCategoryFilter
        isTitle={false}
        handleFiltersClear={resetFilters}
        handleCategoriesShow={() => trackCategoriesFilterClick(id)}
        handleBrandsShow={() => trackBrandsFilterClick(id)}
        handleProductFeaturesShow={() => trackProductFeaturesClick(id)}
        handleCuisineFeaturesShow={() => trackCuisineFeaturesClick(id)}
        handleOfferFeaturesShow={() => trackOfferFeaturesOpen()}
        calculatePositionFromParent
        portalNode={document.getElementById('template-list-toolbar')}
        handleFiltersApply={handleFiltersApply}
        selectedFilters={filteringSettings.filters}
        availableFilters={availableFilters}
      />
    </TemplateFiltersContainer>
  )
}

export default TemplateFilters
