import templates from './templates'
import templateItems from './templateItems'
import templateCart from './templateCart'
import templateSidebar from './templateSidebar'
import templateProductDetails from './templateProductDetails'

export * from './templates'
export * from './templateItems'
export * from './templateCart'
export * from './templateSidebar'

export const templatesInfoWatchers = [
  ...templates,
  ...templateItems,
  ...templateCart,
  ...templateSidebar,
  ...templateProductDetails,
]
