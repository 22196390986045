import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { formatPrice } from 'utils'

import ProductImage from 'components/Product/ProductImage'
import OrderStatusTag from 'views/Orders/OrderStatusTag'
import {
  CalendarV2 as CalendarIcon,
  DeliveryTruckS as DeliveryIcon,
  ArrowRightV2 as ArrowIcon,
} from 'components/Icons'
import { FloatingPriceWrapper } from 'components/FloatingPrice'
import { ORDER_SOURCE_LABELS } from '../../../consts'

const OrderListItem = ({ order, onShowDetails }) => {
  const { formatMessage } = useIntl()
  const {
    orderId,
    orderStatusId,
    orderNumber,
    totalNet,
    orderDatetime,
    deliveryDate,
    anyFloatingPrice,
    orderSource,
    items,
  } = order

  const totalNetFormatted = useMemo(() => formatPrice(totalNet), [totalNet])
  const orderDatetimeFormatted = useMemo(
    () => dayjs(orderDatetime).format(DATE_TIME_FORMATS.FULL_DATE),
    [orderDatetime],
  )
  const deliveryDateFormatted = useMemo(
    () => dayjs(deliveryDate).format(DATE_TIME_FORMATS.FULL_DATE),
    [deliveryDate],
  )

  const orderSourceMsg = useMemo(
    () =>
      ORDER_SOURCE_LABELS[orderSource]
        ? formatMessage({ id: ORDER_SOURCE_LABELS[orderSource] })
        : orderSource,
    [orderSource],
  )

  return (
    <button
      className="flex flex-col py-5 [&:not(:last-child)]:border-b border-solid border-grey-300 w-full hover:bg-grey-100 lg-orders-list:px-4 lg-orders-list:py-6 lg-orders-list:rounded-lg lg-orders-list:border-none"
      type="button"
      onClick={() => onShowDetails(orderId)}
    >
      {/* mobile variant */}
      <div className="w-full lg-orders-list:hidden">
        <OrderStatusTag orderStatusId={orderStatusId} className="w-fit" />
        <div className="flex gap-2 w-full">
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <span className="text-blue-900 font-semibold text-[13px] leading-6">
                {orderNumber}
              </span>
              <FloatingPriceWrapper hasFloatingPrice={anyFloatingPrice}>
                <span className="text-blue-900 font-semibold text-[13px] leading-6">
                  {totalNetFormatted}
                </span>
              </FloatingPriceWrapper>
            </div>
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <CalendarIcon />
                <span className="text-blue-900 text-[13px] leading-6">
                  {orderDatetimeFormatted}
                </span>
              </div>
              <div className="flex gap-2 items-center">
                <DeliveryIcon />
                <span className="text-blue-900 text-[13px] leading-6">
                  {deliveryDateFormatted}
                </span>
              </div>
            </div>
          </div>
          <span className="text-blue-900 text-[13px] leading-6 hidden lg-orders-list:inline">
            {orderSourceMsg}
          </span>
          <ArrowIcon color={theme.colors.gray5} />
        </div>
      </div>

      {/* desktop variant */}
      <div
        className="hidden lg-orders-list:grid grid-cols-[repeat(6,minmax(0,1fr))127px] w-full gap-[17px]"
        data-test-id={`order#${orderId}`}
      >
        <div className="text-left">
          <span className="text-blue-900 font-medium text-[13px] leading-6">
            {orderNumber}
          </span>
        </div>
        <div className="flex justify-end pr-6">
          <FloatingPriceWrapper hasFloatingPrice={anyFloatingPrice}>
            <span className="text-blue-900 text-[13px] leading-6">
              {totalNetFormatted}
            </span>
          </FloatingPriceWrapper>
        </div>
        <div className="pl-3">
          <OrderStatusTag orderStatusId={orderStatusId} className="w-fit" />
        </div>
        <div className="flex gap-2 items-center pl-4 lg-orders-list:gap-3">
          <CalendarIcon />
          <span className="text-blue-900 text-[13px] leading-6">
            {orderDatetimeFormatted}
          </span>
        </div>
        <div className="flex gap-2 items-center pl-4 lg-orders-list:gap-3">
          <DeliveryIcon />
          <span className="text-blue-900 text-[13px] leading-6">
            {deliveryDateFormatted}
          </span>
        </div>
        <div className="text-left pl-4">
          <span className="text-blue-900 text-[13px] leading-6 hidden lg-orders-list:inline">
            {orderSourceMsg}
          </span>
        </div>
        <div className="flex pr-2 justify-end">
          <ArrowIcon color={theme.colors.gray5} />
        </div>
      </div>

      {!!items.length && (
        <div className="flex flex-col pt-4 gap-4 max-w-full lg-orders-list:pt-2 lg-orders-list:gap-0">
          {items.map(({ product }) => (
            <div
              key={product.id}
              className="flex gap-4 items-center lg-orders-list:py-2"
            >
              {/* <img src={product.images[0].urlSmall} className='w-8 h-8 mix-blend-multiply'/> */}
              <div className="w-8 h-8">
                <ProductImage
                  imageSrc={product.images[0]?.urlSmall}
                  className="mix-blend-multiply"
                />
              </div>
              <span className="text-blue-900 text-[12px] leading-6 text-ellipsis text-nowrap overflow-hidden">
                {product.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </button>
  )
}

export default OrderListItem
