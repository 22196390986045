import React, { useEffect, useMemo, useRef } from 'react'
import { compose } from 'redux'
import _isEmpty from 'lodash/isEmpty'
import withSpinner from 'hocs/withSpinner'
import usePrevious from 'use-previous'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectTemplatesData } from 'containers/Templates'
import { CART_INFO_REDUCER_NAME } from 'containers/Cart/consts'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import {
  TEMPLATES_INFO_REDUCER_NAME,
  TEMPLATES_REDUCER_NAME,
} from 'containers/Templates/consts'

import { ROUTES } from 'consts'
import {
  templateItemsActions,
  templatesActions,
} from 'containers/Templates/actions'

import { getBidfoodTemplate } from './utils'
import TemplatesList from './TemplatesList'
import TemplateDetails from './TemplateDetails'
import { TemplateWrapper, TemplatesPageWrapper } from './styles'

const Templates = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const templates = useSelector(selectTemplatesData)

  const prevTemplates = usePrevious(templates)
  const previousTemplateId = usePrevious(params.id)

  const templateDetails = useMemo(
    () =>
      templates ? templates.find(({ id }) => id === Number(params.id)) : {},
    [templates, params.id],
  )

  const wrapperRef = useRef(null)

  useEffect(() => {
    dispatch(templatesActions.delta())

    return () => {
      dispatch(templatesActions.clear())
      dispatch(templateItemsActions.clear())
    }
  }, [])

  useEffect(
    () => {
      if (!previousTemplateId && !params.id && !_isEmpty(templates)) {
        const { id } = templates[0]
        navigate(generatePath(ROUTES.TEMPLATE_DETAILS, { id }), {
          replace: true,
        })
      } else if (prevTemplates?.length > templates.length && templates.length) {
        const nextTemplate =
          getBidfoodTemplate(templates) || templates[templates - 1]
        navigate(
          generatePath(ROUTES.TEMPLATE_DETAILS, { id: nextTemplate?.id }),
          {
            replace: true,
          },
        )
      } else if (previousTemplateId && !params.id) {
        navigate(
          generatePath(ROUTES.TEMPLATE_DETAILS, { id: previousTemplateId }),
          {
            replace: true,
          },
        )
      }
    },
    [params.id, templates, prevTemplates, previousTemplateId, navigate],
  )

  return (
    <TemplatesPageWrapper ref={wrapperRef}>
      <Breadcrumbs />
      <TemplateWrapper>
        <TemplatesList
          templates={templates}
          createTemplate={(...args) =>
            dispatch(templatesActions.createDelta(...args))
          }
        />
        <TemplateDetails templateDetails={templateDetails} />
      </TemplateWrapper>
    </TemplatesPageWrapper>
  )
}

export default compose(
  withSpinner([
    CART_INFO_REDUCER_NAME,
    [TEMPLATES_INFO_REDUCER_NAME, TEMPLATES_REDUCER_NAME],
  ]),
)(Templates)
