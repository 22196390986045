import React from 'react'

import theme from 'theme'

const DeliveryTruckS = ({ color = theme.colors.gray5 }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 6H5" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
    <path
      d="M10 5H13L15 8V9V11H13"
      stroke={color}
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <path
      d="M4 3H8C9.10457 3 10 3.89543 10 5V11"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <path
      d="M5 11L10 11"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <circle cx="3.5" cy="11.5" r="1.5" stroke={color} strokeWidth="1.4" />
    <circle cx="11.5" cy="11.5" r="1.5" stroke={color} strokeWidth="1.4" />
  </svg>
)

export default DeliveryTruckS
