import { rtkApi } from 'services/api/rtkApi'
import { getFilters } from 'containers/Products/Filters/api'

export const RTK_TEMPLATE_FILTERS_TAG = 'TemplateFilters'

const templates = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_TEMPLATE_FILTERS_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getTemplateFilters: build.query({
        query: ({ id, selectedFiltersParams } = {}) =>
          getFilters({ templateId: id, selectedFiltersParams }),
        providesTags: (result, error, params) => [
          { type: RTK_TEMPLATE_FILTERS_TAG, id: params.id },
        ],
      }),
    }),
  })

export const { useLazyGetTemplateFiltersQuery } = templates
