import { makeMessages, makeTranslation } from 'utils'

const messages = {
  ordersTitle: { id: 'navbar.orders' },
  gross: { id: 'prices.gross' },
  orderDate: 'list.orderDate',
  deliveryDate: 'list.deliveryDate',
  currentMonth: 'list.currentMonth',
  status0: 'details.status.0',
  status1: 'details.status.1',
  status2: 'details.status.2',
  status3: 'details.status.3',
  status4: 'details.status.4',
  seeDetails: 'list.seeDetails',
  scheduledDeliveryDate: 'list.scheduledDeliveryDate',
  noOrdersMessage: 'list.noOrdersMessage',
  noOrdersCtaBtn: 'list.noOrdersCtaBtn',
  noSearchResultsMessage: 'list.noSearchResultsMessage',
  noSearchResultsCtaBtn: 'list.noSearchResultsCtaBtn',
  source: 'list.source',
  orders: { id: 'navbar.orders' },
  searchPlaceholder: 'list.searchPlaceholder',
}

export default makeMessages(messages, makeTranslation('orders'))
